.Loader {
    --loader-color: #054da7;
    --loader-size: 48px;
    --loader-border-width: 4px;
    --loader-speed: 1.5s;

    display: block;
    position: fixed;
    width: var(--loader-size);
    height: var(--loader-size);
    inset: 0;
    margin: auto;
}

.Loader div {
    --loader-inner-size: calc(var(--loader-size) - var(--loader-border-width) * 2);

    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--loader-inner-size);
    height: var(--loader-inner-size);
    margin: var(--loader-border-width);
    border: calc(var(--loader-border-width) * 0.25) solid var(--loader-color);
    border-color: var(--loader-color) transparent transparent transparent;
    border-radius: 50%;
    animation: loader var(--loader-speed) cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.Loader div:nth-child(1) {
    border-width: calc(var(--loader-border-width) * 0.5);
    animation-delay: calc(var(--loader-speed) * -0.125);
}

.Loader div:nth-child(2) {
    border-width: calc(var(--loader-border-width) * 0.75);
    animation-delay: calc(var(--loader-speed) * -0.25);
}

.Loader div:nth-child(3) {
    border-width: calc(var(--loader-border-width));
    animation-delay: calc(var(--loader-speed) * -0.375);
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
